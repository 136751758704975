import React, { Component } from 'react';
import loadScript from 'simple-load-script';
import Layout from "../components/layout/layout"
import PreLoader from '../components/preloader';
import Notfound from '../components/notfound';
import SEO from "../components/seo"


class Page404 extends Component {

  componentCleanup() { 
    let themeJs = document.getElementById("themeJs");
    document.body.removeChild(themeJs);
  }
  componentWillUnmount() {
    this.componentCleanup();
  }

componentDidMount() {
    // loadScript('/js/theme.js', { inBody: true });
    loadScript({url: '/js/theme.js', attrs: { id: 'themeJs'}, inBody: true});
}



  render() { 
      return ( 
          <Layout>
              <PreLoader/>
              <SEO title="Page Not Found (404)!" />
              <Notfound/>
           </Layout>
      );
  }
}


export default Page404

