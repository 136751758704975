import React from "react"

const notfound = () => (
  <>
    {/* <!-- =================================================--> */}
    {/* <!-- <section why?> begin ============================--> */}
    <section className="bg-white py-6 py-md-8" id="default-why-us">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-12 text-center">
            <img style={{width: `80%`}} src="/img/404.png"/>
          </div>
        </div>
      </div>
      {/* <!-- end of .container--> */}
    </section>
    {/* <!-- <section why? > close ============================--> */}
    {/* <!-- ========================================--></section> */}
  </>
)

export default notfound
